<template>
  <div>
    <div>
      <van-swipe class="my-swipe"
        :autoplay="3000"
        indicator-color="white">
        <van-swipe-item class="homebanner">
          <img :src="banner" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="cord">
      <router-link to="/dengji/oldmanAdd/0">
        <div style="width: 30%;float: left;margin:0 10px;">
          <img :src="dengji"
            style="width:70px;height:70px;">
        </div>
        <div style="line-height: 40px;">
          <span style="font-size:20px;font-weight:550;">信息登记</span><br />
          请填写正确的身份信息
        </div>
      </router-link>
    </div>
    <div class="cord">
      <router-link to="/dengji/lrdjChaXun/">
        <div style="width: 30%;float: left;margin:0 10px;">
          <img :src="chaxun"
            style="width:70px;height:70px;">
        </div>
        <div style="line-height: 40px;">
          <span style="font-size:20px;font-weight:550;">信息查询</span><br />
          查看已登记人员信息
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  data () {
    return {
      banner: "https://lycqljdapi.dexian.ren//upload/images/2021/9/db57033a-66b.png",
      dengji: "https://lycqljdapi.dexian.ren//upload/images/2021/9/e875438d-a76.png",
      chaxun: "https://lycqljdapi.dexian.ren//upload/images/2021/9/b2473090-42d.png",
    }
  },
}  
</script>
<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.cord {
  border: 1px solid #d3cfdb;
  border-radius: 10px;
  width: 90%;
  margin: 20px auto;
  padding: 10px;
  color: black;
}
.cord a {
  color: black;
}
</style>